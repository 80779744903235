import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _41c45f1c = () => interopDefault(import('../pages/wrapper.vue' /* webpackChunkName: "wrapper" */))
const _cd863b84 = () => interopDefault(import('../pages/careers.vue' /* webpackChunkName: "careers" */))
const _69f75e12 = () => interopDefault(import('../pages/teltonika-energy.com-landing.vue' /* webpackChunkName: "teltonika-energy.com-landing" */))
const _73ff5b1d = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "news" */))
const _f2195954 = () => interopDefault(import('../pages/productsEnergy/index.vue' /* webpackChunkName: "productsEnergy" */))
const _2673e8af = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "search" */))
const _928d5fe4 = () => interopDefault(import('../pages/dynamic-newsletter.vue' /* webpackChunkName: "dynamic-newsletter" */))
const _ea1bd202 = () => interopDefault(import('../pages/defaultContent.vue' /* webpackChunkName: "null" */))
const _fd754a6e = () => interopDefault(import('../pages/use-cases/index.vue' /* webpackChunkName: "use-cases" */))
const _68d94448 = () => interopDefault(import('../pages/become-our-partner.vue' /* webpackChunkName: "become-our-partner" */))
const _43c793cc = () => interopDefault(import('../pages/contacts.vue' /* webpackChunkName: "contacts" */))
const _17157922 = () => interopDefault(import('../pages/mission-vision-values.vue' /* webpackChunkName: "mission-vision-values" */))
const _89ee1394 = () => interopDefault(import('../pages/policiesCertificates/index.vue' /* webpackChunkName: "policiesCertificates" */))
const _50b266fa = () => interopDefault(import('../pages/who-we-are-energy.vue' /* webpackChunkName: "who-we-are-energy" */))
const _2ae9892c = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "blog" */))
const _a08b690c = () => interopDefault(import('../pages/product-support.vue' /* webpackChunkName: "product-support" */))
const _f4e2d386 = () => interopDefault(import('../pages/warranty-and-repair.vue' /* webpackChunkName: "warranty-and-repair" */))
const _8d5b5624 = () => interopDefault(import('../pages/policiesCertificates/_slug.vue' /* webpackChunkName: "policiesCertificates" */))
const _7f8eb981 = () => interopDefault(import('../pages/use-cases/_slug.vue' /* webpackChunkName: "use-cases" */))
const _2932e7e4 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "blog" */))
const _7248b9d5 = () => interopDefault(import('../pages/news/_slug.vue' /* webpackChunkName: "news" */))
const _f5869be4 = () => interopDefault(import('../pages/productsEnergy/_slug.vue' /* webpackChunkName: "productsEnergy" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fabout-us","lt":"\u002Fapie-mus"},"page_id":14},
    name: "about-us-14___en"
  }, {
    path: "/career",
    component: _cd863b84,
    meta: {"paths":{"en":"\u002Fcareer","lt":"\u002Fkarjera"},"page_id":21},
    name: "career-21___en"
  }, {
    path: "/industries",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Findustries","lt":"\u002Findustrija"},"page_id":59},
    name: "industries-59___en"
  }, {
    path: "/lt",
    component: _69f75e12,
    meta: {"paths":{"en":"\u002F","lt":"\u002F"},"page_id":26},
    name: "teltonika-energy.com-landing-26___lt"
  }, {
    path: "/news",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fnews","lt":"\u002Fnaujienos-1"},"page_id":7},
    name: "news-7___en"
  }, {
    path: "/newsroom",
    component: _73ff5b1d,
    meta: {"paths":{"en":"\u002Fnewsroom","lt":"\u002Fnaujienos"},"page_id":8},
    name: "newsroom-8___en"
  }, {
    path: "/products",
    component: _f2195954,
    meta: {"paths":{"en":"\u002Fproducts","lt":"\u002Fproducts"},"page_id":197},
    name: "products-197___en"
  }, {
    path: "/products-wrapper",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fproducts-wrapper","lt":"\u002Fproduktai-wrapper"},"page_id":54},
    name: "products-wrapper-54___en"
  }, {
    path: "/resources",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fresources","lt":"\u002Fresursai"},"page_id":28},
    name: "resources-28___en"
  }, {
    path: "/search",
    component: _2673e8af,
    meta: {"paths":{"en":"\u002Fsearch","lt":"\u002Fpaieska"},"page_id":45},
    name: "search-45___en"
  }, {
    path: "/subscribe",
    component: _928d5fe4,
    meta: {"paths":{"en":"\u002Fsubscribe","lt":"\u002Fprenumeruok"},"page_id":64},
    name: "subscribe-64___en"
  }, {
    path: "/subscribe-old",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Fsubscribe-old","lt":"\u002Fprenumeruoti-old"},"page_id":13},
    name: "subscribe-old-13___en"
  }, {
    path: "/support",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fsupport","lt":"\u002Fsupport"},"page_id":63},
    name: "support-63___en"
  }, {
    path: "/translations",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Ftranslations"}},
    name: "defaultContent___en"
  }, {
    path: "/use-cases",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases","lt":"\u002Fpritaikymo-sritys"},"page_id":202},
    name: "use-cases-202___en"
  }, {
    path: "/about-us/become-our-partner",
    component: _68d94448,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fbecome-our-partner","lt":"\u002Fapie-mus\u002Fbecome-our-partner"},"page_id":62},
    name: "about-us-become-our-partner-62___en"
  }, {
    path: "/about-us/contacts",
    component: _43c793cc,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fcontacts","lt":"\u002Fapie-mus\u002Fkontaktai"},"page_id":19},
    name: "about-us-contacts-19___en"
  }, {
    path: "/about-us/mission-vision-values",
    component: _17157922,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fmission-vision-values","lt":"\u002Fapie-mus\u002Fmisija-vizija-ir-vertybes"},"page_id":16},
    name: "about-us-mission-vision-values-16___en"
  }, {
    path: "/about-us/policies-certificates",
    component: _89ee1394,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai"},"page_id":18},
    name: "about-us-policies-certificates-18___en"
  }, {
    path: "/about-us/who-we-are",
    component: _50b266fa,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fwho-we-are","lt":"\u002Fapie-mus\u002Fwho-we-are"},"page_id":60},
    name: "about-us-who-we-are-60___en"
  }, {
    path: "/industries/commercial-buildings",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Findustries\u002Fcommercial-buildings","lt":"\u002Findustrija\u002Fkomerciniai-pastatai"},"page_id":209},
    name: "industries-commercial-buildings-209___en"
  }, {
    path: "/industries/residential",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Findustries\u002Fresidential","lt":"\u002Findustrija\u002Fresidential"},"page_id":207},
    name: "industries-residential-207___en"
  }, {
    path: "/lt/apie-mus",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fabout-us","lt":"\u002Fapie-mus"},"page_id":14},
    name: "about-us-14___lt"
  }, {
    path: "/lt/karjera",
    component: _cd863b84,
    meta: {"paths":{"en":"\u002Fcareer","lt":"\u002Fkarjera"},"page_id":21},
    name: "career-21___lt"
  }, {
    path: "/lt/industrija",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Findustries","lt":"\u002Findustrija"},"page_id":59},
    name: "industries-59___lt"
  }, {
    path: "/lt/naujienos-1",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fnews","lt":"\u002Fnaujienos-1"},"page_id":7},
    name: "news-7___lt"
  }, {
    path: "/lt/naujienos",
    component: _73ff5b1d,
    meta: {"paths":{"en":"\u002Fnewsroom","lt":"\u002Fnaujienos"},"page_id":8},
    name: "newsroom-8___lt"
  }, {
    path: "/lt/products",
    component: _f2195954,
    meta: {"paths":{"en":"\u002Fproducts","lt":"\u002Fproducts"},"page_id":197},
    name: "products-197___lt"
  }, {
    path: "/lt/produktai-wrapper",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fproducts-wrapper","lt":"\u002Fproduktai-wrapper"},"page_id":54},
    name: "products-wrapper-54___lt"
  }, {
    path: "/lt/resursai",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fresources","lt":"\u002Fresursai"},"page_id":28},
    name: "resources-28___lt"
  }, {
    path: "/lt/paieska",
    component: _2673e8af,
    meta: {"paths":{"en":"\u002Fsearch","lt":"\u002Fpaieska"},"page_id":45},
    name: "search-45___lt"
  }, {
    path: "/lt/prenumeruok",
    component: _928d5fe4,
    meta: {"paths":{"en":"\u002Fsubscribe","lt":"\u002Fprenumeruok"},"page_id":64},
    name: "subscribe-64___lt"
  }, {
    path: "/lt/prenumeruoti-old",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Fsubscribe-old","lt":"\u002Fprenumeruoti-old"},"page_id":13},
    name: "subscribe-old-13___lt"
  }, {
    path: "/lt/support",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fsupport","lt":"\u002Fsupport"},"page_id":63},
    name: "support-63___lt"
  }, {
    path: "/lt/translations",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Ftranslations"}},
    name: "defaultContent___lt"
  }, {
    path: "/lt/pritaikymo-sritys",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases","lt":"\u002Fpritaikymo-sritys"},"page_id":202},
    name: "use-cases-202___lt"
  }, {
    path: "/resources/blogs",
    component: _2ae9892c,
    meta: {"paths":{"en":"\u002Fresources\u002Fblogs","lt":"\u002Fresursai\u002Fblogs"},"page_id":89},
    name: "resources-blogs-89___en"
  }, {
    path: "/support/product-support",
    component: _a08b690c,
    meta: {"paths":{"en":"\u002Fsupport\u002Fproduct-support","lt":"\u002Fsupport\u002Fproduct-support"},"page_id":61},
    name: "support-product-support-61___en"
  }, {
    path: "/support/warranty-repair",
    component: _f4e2d386,
    meta: {"paths":{"en":"\u002Fsupport\u002Fwarranty-repair","lt":"\u002Fsupport\u002Fwarranty-repair"},"page_id":189},
    name: "support-warranty-repair-189___en"
  }, {
    path: "/use-cases/accommodation",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Faccommodation","lt":"\u002Fpritaikymo-sritys\u002Faccommodation"},"page_id":203},
    name: "use-cases-accommodation-203___en"
  }, {
    path: "/lt/apie-mus/become-our-partner",
    component: _68d94448,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fbecome-our-partner","lt":"\u002Fapie-mus\u002Fbecome-our-partner"},"page_id":62},
    name: "about-us-become-our-partner-62___lt"
  }, {
    path: "/lt/apie-mus/kontaktai",
    component: _43c793cc,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fcontacts","lt":"\u002Fapie-mus\u002Fkontaktai"},"page_id":19},
    name: "about-us-contacts-19___lt"
  }, {
    path: "/lt/apie-mus/misija-vizija-ir-vertybes",
    component: _17157922,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fmission-vision-values","lt":"\u002Fapie-mus\u002Fmisija-vizija-ir-vertybes"},"page_id":16},
    name: "about-us-mission-vision-values-16___lt"
  }, {
    path: "/lt/apie-mus/politika-ir-sertifikatai",
    component: _89ee1394,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai"},"page_id":18},
    name: "about-us-policies-certificates-18___lt"
  }, {
    path: "/lt/apie-mus/who-we-are",
    component: _50b266fa,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fwho-we-are","lt":"\u002Fapie-mus\u002Fwho-we-are"},"page_id":60},
    name: "about-us-who-we-are-60___lt"
  }, {
    path: "/lt/industrija/komerciniai-pastatai",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Findustries\u002Fcommercial-buildings","lt":"\u002Findustrija\u002Fkomerciniai-pastatai"},"page_id":209},
    name: "industries-commercial-buildings-209___lt"
  }, {
    path: "/lt/industrija/residential",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Findustries\u002Fresidential","lt":"\u002Findustrija\u002Fresidential"},"page_id":207},
    name: "industries-residential-207___lt"
  }, {
    path: "/lt/resursai/blogs",
    component: _2ae9892c,
    meta: {"paths":{"en":"\u002Fresources\u002Fblogs","lt":"\u002Fresursai\u002Fblogs"},"page_id":89},
    name: "resources-blogs-89___lt"
  }, {
    path: "/lt/support/product-support",
    component: _a08b690c,
    meta: {"paths":{"en":"\u002Fsupport\u002Fproduct-support","lt":"\u002Fsupport\u002Fproduct-support"},"page_id":61},
    name: "support-product-support-61___lt"
  }, {
    path: "/lt/support/warranty-repair",
    component: _f4e2d386,
    meta: {"paths":{"en":"\u002Fsupport\u002Fwarranty-repair","lt":"\u002Fsupport\u002Fwarranty-repair"},"page_id":189},
    name: "support-warranty-repair-189___lt"
  }, {
    path: "/lt/pritaikymo-sritys/accommodation",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Faccommodation","lt":"\u002Fpritaikymo-sritys\u002Faccommodation"},"page_id":203},
    name: "use-cases-accommodation-203___lt"
  }, {
    path: "/",
    component: _69f75e12,
    meta: {"paths":{"en":"\u002F","lt":"\u002F"},"page_id":26},
    name: "teltonika-energy.com-landing-26___en"
  }, {
    path: "/lt/apie-mus/politika-ir-sertifikatai/:slug",
    component: _8d5b5624,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai\u002F:slug"},"page_id":18},
    name: "about-us-policies-certificates-:slug-18-slug___lt"
  }, {
    path: "/lt/industrija/komerciniai-pastatai/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Findustries\u002Fcommercial-buildings\u002F:slug","lt":"\u002Findustrija\u002Fkomerciniai-pastatai\u002F:slug"},"page_id":209},
    name: "industries-commercial-buildings-:slug-209-slug___lt"
  }, {
    path: "/lt/industrija/residential/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Findustries\u002Fresidential\u002F:slug","lt":"\u002Findustrija\u002Fresidential\u002F:slug"},"page_id":207},
    name: "industries-residential-:slug-207-slug___lt"
  }, {
    path: "/lt/resursai/blogs/:slug",
    component: _2932e7e4,
    meta: {"paths":{"en":"\u002Fresources\u002Fblogs\u002F:slug","lt":"\u002Fresursai\u002Fblogs\u002F:slug"},"page_id":89},
    name: "resources-blogs-:slug-89-slug___lt"
  }, {
    path: "/lt/pritaikymo-sritys/accommodation/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Faccommodation\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Faccommodation\u002F:slug"},"page_id":203},
    name: "use-cases-accommodation-:slug-203-slug___lt"
  }, {
    path: "/about-us/policies-certificates/:slug",
    component: _8d5b5624,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai\u002F:slug"},"page_id":18},
    name: "about-us-policies-certificates-:slug-18-slug___en"
  }, {
    path: "/industries/commercial-buildings/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Findustries\u002Fcommercial-buildings\u002F:slug","lt":"\u002Findustrija\u002Fkomerciniai-pastatai\u002F:slug"},"page_id":209},
    name: "industries-commercial-buildings-:slug-209-slug___en"
  }, {
    path: "/industries/residential/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Findustries\u002Fresidential\u002F:slug","lt":"\u002Findustrija\u002Fresidential\u002F:slug"},"page_id":207},
    name: "industries-residential-:slug-207-slug___en"
  }, {
    path: "/lt/naujienos/:slug",
    component: _7248b9d5,
    meta: {"paths":{"en":"\u002Fnewsroom\u002F:slug","lt":"\u002Fnaujienos\u002F:slug"},"page_id":8},
    name: "newsroom-:slug-8-slug___lt"
  }, {
    path: "/lt/products/:slug",
    component: _f5869be4,
    meta: {"paths":{"en":"\u002Fproducts\u002F:slug","lt":"\u002Fproducts\u002F:slug"},"page_id":197},
    name: "products-:slug-197-slug___lt"
  }, {
    path: "/lt/pritaikymo-sritys/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002F:slug"},"page_id":202},
    name: "use-cases-:slug-202-slug___lt"
  }, {
    path: "/resources/blogs/:slug",
    component: _2932e7e4,
    meta: {"paths":{"en":"\u002Fresources\u002Fblogs\u002F:slug","lt":"\u002Fresursai\u002Fblogs\u002F:slug"},"page_id":89},
    name: "resources-blogs-:slug-89-slug___en"
  }, {
    path: "/use-cases/accommodation/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Faccommodation\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002Faccommodation\u002F:slug"},"page_id":203},
    name: "use-cases-accommodation-:slug-203-slug___en"
  }, {
    path: "/newsroom/:slug",
    component: _7248b9d5,
    meta: {"paths":{"en":"\u002Fnewsroom\u002F:slug","lt":"\u002Fnaujienos\u002F:slug"},"page_id":8},
    name: "newsroom-:slug-8-slug___en"
  }, {
    path: "/products/:slug",
    component: _f5869be4,
    meta: {"paths":{"en":"\u002Fproducts\u002F:slug","lt":"\u002Fproducts\u002F:slug"},"page_id":197},
    name: "products-:slug-197-slug___en"
  }, {
    path: "/use-cases/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002F:slug","lt":"\u002Fpritaikymo-sritys\u002F:slug"},"page_id":202},
    name: "use-cases-:slug-202-slug___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
